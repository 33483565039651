import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/tracer',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
)

export function apiGetFaults({
  makeModelIds,
  stationIds,
  start_at,
  end_at,
  station_alerts,
  plants,
  zones,
  teams,
  department,
}) {
  const params = new URLSearchParams({ start_at, end_at })
  if (makeModelIds.length > 0) params.set('makeModelIds', makeModelIds.join())
  if (stationIds.length > 0) params.set('stationIds', stationIds.join())
  if (station_alerts && station_alerts.length > 0)
    params.set('station_alerts', station_alerts)
  if (plants && plants.length > 0) params.set('plants', plants)
  if (zones && zones.length > 0) params.set('zones', zones)
  if (teams && teams.length > 0) params.set('teams', teams)
  if (department) params.set('department', department)
  return api.get(`/faults?${params.toString()}`).then((resp) => resp.data)
}
